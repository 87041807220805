<template>
  <div>
    <PageDynamicBreadcrumb custom-current-page="Overview" />
    <PageHeader title="Keyword Exclusions Overview">
      <template v-slot:actions>
        <q-btn
          label="New Exclusion"
          to="/manage/keywords/exclusions/create"
          color="primary"
        />
      </template>
    </PageHeader>

    <DataTable
      table-key="keywordExclusionsOverview"
      row-key="id"
      title="Keyword Exclusions"
      :columns="columns"
      :rows="keywordExclusions"
      :loading="loading"
      :downloadable="true"
      :refreshable="false"
      :customizable="false"
      :filterable="true"
      :searchable="true"
      :disable-state="true"
    >
      <template v-slot:actions="row">
        <ActionsDropdownButton
          :actions="[
            {
              action: () => promptDeleteKeywordExclusion(row),
              label: 'Delete'
            }
          ]"
        />
      </template>
    </DataTable>
  </div>
</template>

<script>
import moment from "moment";
import axios from "axios";
import ConfirmationModalDelete from "@/components/UI/ConfirmationModalDelete";
import { Dialog } from "quasar";
import PageHeader from "@/components/UI/PageHeader";
import PageDynamicBreadcrumb from "@/components/UI/PageDynamicBreadcrumb";
import DataTable from "@/components/DataTable";
import ActionsDropdownButton from "@/components/UI/ActionsDropdownButton";
moment.locale("en");

export default {
  name: "KeywordExclusionsOverview",
  components: {
    ActionsDropdownButton,
    DataTable,
    PageDynamicBreadcrumb,
    PageHeader
  },
  data() {
    return {
      loading: true,
      keywordExclusionsList: [],
      columns: [
        {
          name: "business_account_id",
          label: "Business Account ID",
          field: "business_account_id",
          sortable: true,
          sort: (a, b) => this.sortInteger(a, b),
          type: "dimension"
        },
        {
          name: "business_account_name",
          label: "Business Account Name",
          field: "business_account_name",
          sortable: true,
          type: "dimension"
        },
        {
          name: "brand_id",
          label: "Brand ID",
          field: "brand_id",
          sortable: true,
          sort: (a, b) => this.sortInteger(a, b),
          type: "dimension"
        },
        {
          name: "brand_name",
          label: "Brand Name",
          field: "brand_name",
          sortable: true,
          type: "dimension"
        },
        {
          name: "keyword_id",
          label: "Keyword ID",
          field: "keyword_id",
          sortable: true,
          sort: (a, b) => this.sortInteger(a, b),
          type: "dimension"
        },
        {
          name: "keyword",
          label: "Keyword",
          field: "keyword",
          sortable: true,
          type: "dimension"
        },
        {
          name: "time_created",
          label: "Time Excluded",
          field: row => moment.utc(row.time_created).local(),
          format: val =>
            !val
              ? "-"
              : moment
                  .utc(val)
                  .local()
                  .format("lll"),
          sortable: true,
          sort: (a, b) => this.sortDate(a, b),
          type: "metric",
          metricType: "date"
        },
        {
          name: "actions",
          label: "",
          field: "",
          filterable: false
        }
      ]
    };
  },
  computed: {
    keywordExclusions() {
      return this.loading ? [] : this.keywordExclusionsList;
    }
  },
  mounted() {
    this.fetchKeywordExclusions();
  },
  methods: {
    fetchKeywordExclusions() {
      axios({
        url: "https://api-v2.jumpfeed.com/internal/keywords/exclusions"
      })
        .then(resp => {
          this.keywordExclusionsList = resp.data.data;
          this.loading = false;
        })
        .catch(err => {
          console.log(err);
        });
    },
    promptDeleteKeywordExclusion(exclusion) {
      Dialog.create({
        component: ConfirmationModalDelete,
        componentProps: {
          descriptor:
            exclusion.brand_name +
            " - " +
            exclusion.keyword +
            " (" +
            exclusion.keyword_id +
            ")"
        }
      }).onOk(dialog => {
        dialog.submitting = true;
        axios
          .post(
            "https://api-v2.jumpfeed.com/internal/keywords/exclusions/delete",
            {
              id: exclusion.id
            }
          )
          .then(() => {
            this.triggerActionOutcomeAlert("delete", true);
            dialog.hide();
            this.fetchKeywordExclusions();
          })
          .catch(err => {
            console.log(err);
            this.triggerActionOutcomeAlert("delete", false);
          })
          .finally(() => {
            dialog.submitting = false;
          });
      });
    }
  }
};
</script>

<style scoped lang="scss"></style>
